import React from 'react'
import { Link } from 'gatsby'

import logo from '../../content/assets/logo.svg'
import '../styles/header.scss'

export default ({ current, overlap }) => {
  return (
    <header className={`navbar ${overlap ? 'overlap' : ''}`}>
      <Link to='/'><img width='100' src={logo} alt='Nucleus' /></Link>
      <div>
        {current !== 'pricing' && <Link to='/pricing'>Pricing</Link>}
        {current !== 'blog' && <Link class='smallHide' to='/blog'>Blog</Link>}
        <a class='smallHide' href='https://docs.nucleus.sh'>Docs</a>
        <a href='https://dash.nucleus.sh/login'>Login</a>
        <a className='btn secondary' href='https://dash.nucleus.sh/analytics?demo=1'>Live Demo</a>
        <a className='smallHide btn primary' href='https://dash.nucleus.sh/signup'>Start Free Trial</a>
      </div>
    </header>
  )
}
