import React from 'react'
import { Link } from 'gatsby'
import Header from './header'

export default ({ current, children }) => {
  return (
    <div class={current}>
      <Header current={current} />
      <main>{children}</main>
      <footer className='footer'>
        <div class='row'>
          <div class='col'>
            <h3>Company</h3>
            <p>{`© ${new Date().getFullYear()} Reality Software Inc. // Nucleus.sh`}</p>
          </div>
          <div class='col'>
            <h3>Resources</h3>
            <Link to='/docs' />
            <a href='https://docs.nucleus.sh'>Documentation</a>
            <Link to='/pricing'>Pricing</Link>
            <Link to='/transparency'>Data Transparency</Link>
            <Link to='/terms'>Terms and conditions</Link>
            <Link to='/privacy'>Privacy policy</Link>
            <a href='https://docs.nucleus.sh/compliance' target='_blank' rel='noreferrer'>Compliance</a>
          </div>
          <div class='col'>
            <h3>Product</h3>
            <a href='https://headwayapp.co/nucleus-analytics-changelog' target='_blank' rel='noreferrer'>Changelog</a>
            <Link to='/nodejs'>Node.js analytics</Link>
            <Link to='/electron'>Electron analytics</Link>
            <Link to='/python'>Python analytics</Link>
            <Link to='/swift'>Swift analytics</Link>
            <a href='https://docs.nucleus.sh/reference/tracking-api'>Desktop Analytics API</a>
          </div>
          <div class='col'>
            <h3>Contact Us</h3>
            <p>
              <span>+523326303147</span>
              <br />
              <a href='mailto:hello@nucleus.sh'>Email: hello@nucleus.sh</a>
            </p>
            <p>
              2115, 1007 N Orange St. 4th Floor <br />
              Wilmington, DE, New Castle
              United States
            </p>
          </div>
        </div>
      </footer>
    </div>
  )
}
